@use '../abstracts' as *
    
// Text meant only for screen readers.
.screen-reader-text
    border: 0
    clip: rect(1px, 1px, 1px, 1px)
    clip-path: inset(50%)
    height: 1px
    margin: -1px
    overflow: hidden
    padding: 0
    position: absolute !important
    width: 1px
    word-wrap: normal !important



.centre-view
    margin: 0 auto
    width: 100%

    @each $center-vw-sizes-lable, $center-vw-sizes-map in $center-vw-map
        @if $center-vw-sizes-lable == default
            @include center-vw($center-vw-sizes-lable, $center-vw-sizes-map)         
        @else
            &.#{$center-vw-sizes-lable}
                @include center-vw($center-vw-sizes-lable, $center-vw-sizes-map)



///////////////
// Gutenberg //
///////////////

.aligncenter
    clear: both
    text-align: center



///////////////////
// Custom Cursor //
///////////////////
.cursor-container
    position: fixed
    pointer-events: none 
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: (10000 * 10) - 1
    mix-blend-mode: difference

    #js-cursor
        position: absolute
        top: -1.5rem
        left: -1.5rem
        z-index: 900
        width: 3rem
        height: 3rem

        pointer-events: none
        mix-blend-mode: difference
        //-webkit-mix-blend-mode: hard-light
        //mix-blend-mode: hard-light
        transition: left 0.5s ease-in-out 0.5s
        filter: invert(1)

        &:before,
        &:after
            content: ""
            opacity: 0
            position: absolute
            top: 50%
            left: -1rem

            transition: all 0.5s ease-in-out

        &:before
            width: 0.5rem
            height: 0.5rem
            left: -2.25rem
            transform: translateY(-50%)
            background: white
            border-radius: 100%

        &:after
            width: 0
            height: 0 
            border-top: 0.7rem solid transparent
            border-bottom: 0.7rem solid transparent
            border-right: 0.7rem solid white
            transform: translateY(-50%)

        &.choice
            left: 2rem

            &:before,
            &:after
                opacity: 1

                transition: all 0.3s ease-in-out 0.6s

            &:before
                opacity: 1

                transition: all 0.3s ease-in-out 0.8s
        
        &__big,
        &__small
            &.display
                display: block

            &.hide
                display: block
        
        &__small
            position: absolute
            top: 50%
            left: 50%
            z-index: 5
            transform: scale(0)
            width: 0.5rem
            height: 0.5rem
            border-radius: 50%
            margin: -0.25rem 0 0 -0.25rem

            background-color: var(--pref-color-dark-0)
            transition: all 0.5s ease-in-out
            mix-blend-mode: difference

            &:after,
            &:before
                content: ""
                background-color: var(--pref-color-dark-0)
                transition: all 0.3s ease-in-out
                width: 0
                height: 0
                opacity: 0

            &.on-screen
                transform: scale(1)

            &.active
                transition: all 0.5s ease-in-out
                transform: scale(3)

            &.text
                width: 0.25rem
                height: 2rem
                border-radius: 0
                transition: all 0.2s ease-in-out
                margin: -1rem 0 0 -0.125rem

                &:after,
                &:before
                    position: absolute
                    right: 50%
                    transform: translateX(50%)
                    width: 1rem
                    height: 0.25rem
                    opacity: 1
                    transition: all 0.3s ease-in-out 0.3s

                &:before
                    top: 0
                    height: 0.25rem

                &:after
                    right: 50%
                    bottom: 0

            &.choice
                width: 0.25rem
                height: 1.5rem
                border-radius: 0
                transition: all 0.2s ease-in-out
                margin: -1rem 0 0 0.125rem
                transform: rotate(45deg)

                &:before
                    content: ""
                    position: absolute
                    right: 0
                    bottom: 0
                    width: 0.8rem
                    height: 0.25rem
                    background-color: var(--pref-color-dark-0)
                    opacity: 1
        
        &__big
            position: relative
            width: 100%
            height: 100%
            border-radius: 50%
            border: var(--pref-color-dark-0) px-rem(5) solid
            z-index: 1
            opacity: 1
            transition: all 0.5s ease-in-out 0.1s
            transform: scale(0)
            mix-blend-mode: difference

            &.on-screen
                transform: scale(1)

            &.active
                transition: all 0.5s ease-in-out
                transform: scale(1)

            &.choice
                transform: scale(1.2)
                border: var(--pref-color-dark-0) px-rem(3) solid
                border-radius: 0

        &__debug-dialogue
            position: absolute
            top: calc(100% + px-rem(20))
            padding: px-rem(10) px-rem(20)
            white-space: nowrap
            background-color: var(--pref-color-dark-0)
            color: var(--pref-color-dark-100)

            &:before
                content: ""
                position: absolute
                top: px-rem(-10)
                left: px-rem(10)
                transform: translateX(-50%, 50%)
                width: 0
                height: 0
                border-color: transparent transparent var(--pref-color-dark-0) transparent
                border-style: solid
                border-top-width: px-rem(0)
                border-left-width: px-rem(20)
                border-right-width: px-rem(20)
                border-bottom-width: px-rem(15)




/////////////////////
// Page scroll bar //
/////////////////////

// Scroll bar dimensions
::-webkit-scrollbar
    width: px-rem(15, m)
    height: px-rem(15, m)

    @include at-least($mobile)
        width: px-rem(15)
        height: px-rem(15)
  
// Scroll bar track
::-webkit-scrollbar-track
    background-color: var(--main-bg-color-alt)
    border-radius: px-rem(20, m)

    @include at-least($mobile)
        width: px-rem(20)
        height: px-rem(20)
  
// Scroll bar track
body::-webkit-scrollbar-track
    background-color: var(--main-bg-color-alt)
    border-radius: 0px

// Scroll bar handle
::-webkit-scrollbar-thumb
    background-color: var(--brand-color)
    border: 3px solid var(--main-bg-color-alt)
    border-radius: px-rem(20, m)
    transition: all 0.3s ease-in-out

    @include at-least($mobile)
        border-radius: px-rem(20)

// Scroll bar handle:hover
::-webkit-scrollbar-thumb:hover
    background-color: var(--brand-color-alt)

// Works on common browsers
::selection 
    background-color: var(--brand-color)
    color: var(--pref-color-0)

// Mozilla based browsers
::-moz-selection
    background-color: var(--brand-color)
    color: var(--pref-color-0)

// For Other Browsers
::-o-selection
    background-color: var(--brand-color)
    color: var(--pref-color-0)

::-ms-selection
    background-color: var(--brand-color)
    color: var(--pref-color-0)

// For Webkit
::-webkit-selection
    background-color: var(--brand-color)
    color: var(--pref-color-0)
