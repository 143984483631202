@use '../abstracts' as *

.hamburger-bars
    position: absolute
    right: px-rem(26, m)
    top: 50%
    z-index: 200
    display: block
    width: var(--hamburger-width)
    height: var(--hamburger-height)
    transform: translateY(-50%)


    @include at-least($mobile)
        display: none
    
    &.is-active
        [class^=line-]
            &.line-1
                transform: translateY(calc(1 * var(--hamburger-translateY))) translateX(0) rotate(45deg)
            &.line-2
                right: 75%
                left: -40%
            &.line-3
                transform: translateY(calc(-1 * var(--hamburger-translateY))) translateX(0) rotate(-45deg)

    [class^=line-]
        position: absolute
        left: 0
        display: block
        width: var(--hamburger-width)
        height: var(--hamburger-line-height)
        border-radius: px-em(2, 16)
        background-color: var(--pref-color-RV)
        outline: px-rem(1) solid transparent
        transition: all 0.5s, opacity 100ms

        .is-open-menu &
            background-color: var(--brand-color)

        &.line-1
            top: 0

        &.line-2
            width: auto
            top: calc((var(--hamburger-height) / 2) - (var(--hamburger-line-height) / 2))
            left: 30%
            right: 0

        &.line-3
            bottom: 0
