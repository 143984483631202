@use '../abstracts' as *

#fader
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 999999
    display: flex
    align-items: center
    justify-content: center
    pointer-events: none
    background: white
    animation-duration: 300ms
    animation-timing-function: ease-in-out

    &.fade-out
        opacity: 0
        animation-name: fade-out

    &.fade-in
        opacity: 1
        animation-name: fade-in

    .img-container
        max-width: 30%
        width: 100%

        .img
            position: relative
            display: flex
            align-items: center
            @include bg-contain
            padding-top: 25%
            width: 40%
            margin-inline: auto
            overflow: hidden
        
            &:after
                content: ""
                position: absolute
                top: 50%
                display: inline-block
                background: var(--pref-color-RV)
                height: 150%
                width: 40%
                transform: skewX(15deg) translateY(-50%)
                filter: blur(2vw)
                
                animation-name: gloss-anim
                animation-duration: 3s
                animation-iteration-count: infinite
                animation-fill-mode: both
                animation-timing-function: ease
      
@keyframes gloss-anim
    from
        left: -30%
    to
        left: 130%

@keyframes fade-out
    from
        opacity: 1
    to
        opacity: 0

@keyframes fade-in
    from
        opacity: 0
    to
        opacity: 1