@use 'icons' as *
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap')
/*  font-family: 'Inter', sans-serif

@mixin font-icon
    font-family: 'icons' !important
    font-style: normal
    font-weight: normal !important
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

[class^=icon-social]
    line-height: 0
    
    &:before
        @include font-icon

    &[href*="facebook"],
    &.icon-facebook
        @extend .icon-facebook !optional

    &[href*="instagram"],
    &.icon-instagram
        @extend .icon-instagram !optional

    &[href*="linkedin"],
    &.icon-linkedin
        @extend .icon-linkedin !optional

    &[href*="pinterest"],
    &.icon-pinterest
        @extend .icon-pinterest !optional

    &[href*="twitter"],
    &.icon-twitter
        @extend .icon-twitter !optional

    &[href*="youtube"],
    &.icon-youtube
        @extend .icon-youtube !optional