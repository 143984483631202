@use '../abstracts' as *
@use '../components' as *

nav ul,
.menu
    list-style: none
    padding: 0

.main-nav
    display: flex
    justify-content: center
    align-items: center

    @include until($mobile)
        display: block
        position: fixed
        @include props-val([top, right, bottom, left], 0)
        z-index: 100
        width: 100%
        height: 100%
        -webkit-overflow-scrolling: touch
        background: white
        color: black
        opacity: 0
        overflow-y: auto
        transition: opacity 350ms ease, visibility 350ms ease
        visibility: hidden

        .is-open-menu &
            opacity: 1
            visibility: visible

        &.is-active
            height: 100%

    .menu,
    .nav__dropdown-wrapper
        display: flex
        flex-direction: column
        justify-content: center
        text-align: center
        margin: 0
        padding: 0
        min-height: 100%
        list-style: none

        @include at-least($mobile)
            display: inline-block

        > .menu-item
            &:first-child
                margin-left: 0

            &:last-child
                margin-right: 0


        .menu-item
            position: relative
            display: inline-block
            font-size: px-rem(19)
            padding: 0 px-em(20, 19)
            text-transform: capitalize

            @include until($mobile)
                margin: 20px 0
                display: block

            a
                font-weight: 400
                color: var(--pref-color-RV)

                &:hover
                    color: var(--pref-color-11)

            &.current-menu-item,
            &.current-menu-parent,
            &.current-menu-ancestor,
            &.current_page_item,
            &.current_page_parent,
            &.current-page-ancestor
                > a
                    color: var(--brand-color)

                    &:hover
                        color: var(--brand-color-alt)

    // Use this to style sub-menu
    .sub-menu,
    .nav__dropdown
        background-color: var(--pref-color-7)

        &:before
            content: ""
            position: absolute
            top: 0
            right: 50%
            z-index: -1
            height: px-rem(20)
            width: px-rem(20)
            background-color: var(--pref-color-7)
            transform: translate(50%, -50%) rotate(45deg)

        > .menu-item
            white-space: nowrap

            &.menu-item-has-children
                transition: all 0.2s ease-in-out 0.2s

                &.is-active, &.show
                    padding-top: 1.1em
                    background-color: var(--brand-color-alt)
                    transition: all 0.2s ease-in-out

                    > a
                        color: white
    
            a
                font-weight: 400

            > .sub-menu, .nav__dropdown
                top: 100%
                left: 0
                right: 0
                border-top: px-rem(20) solid
                border-bottom: px-rem(10) solid
                border-color: var(--brand-color-alt)

// Use this only for 'show'/'hide' functionality
.sub-menu, .nav__dropdown
    display: none
    margin: px-rem(10, m) px-rem(-20)
    padding: px-rem(20, m) 0
    visibility: hidden
    opacity: 0
    transform: translateY(-5%)
    transition: all 0.5s ease-in-out 0.2s

    @include at-least($mobile)
        position: absolute
        top: calc(100% + #{px-rem(20)})
        left: 0
        display: flex
        flex-flow: column
        margin: 0
        padding: px-rem(10) 0

    a
        opacity: 0
        transition: all 0.3s ease-in-out

    .is-active > &, .show > &
        display: block
        visibility: visible
        opacity: 1
        color: inherit
        transform: translateY(0)
        transition: all 0.5s ease-in-out

        @include at-least($mobile)
            display: flex

        a
            opacity: 1
            transition: all 0.3s ease-in-out 0.2s

        .menu-item-has-children

            &.is-active, &.show
                .sub-menu
                    transition: all 0.2s ease-in-out

            .sub-menu
                transform: translateY(0)
                transition: all 0.2s ease-in-out 0.2s

.social-nav
    .social-icons
        display: flex
        justify-content: center
        list-style: none
        padding: 0
        margin: 0

        li
            margin-left: px-rem(15)
            color: --var(--pref-color-20)
            border-radius: 100%
            border: px-rem(1) solid var(--pref-color-10)

            &:first-child
                margin-left: 0

            &:last-child
                margin-right: 0

            a
                display: flex
                align-items: center
                justify-content: center
                min-height: px-rem(60)
                min-width: px-rem(60)
                color: var(--pref-color-RV)
                font-size: px-rem(22)

.cart-nav,
.toggle-nav
    width: 100%

    .toggles
        display: flex
        justify-content: flex-end

        .item-toggle
            position: relative
            display: flex
            align-items: center
            justify-content: center
            border: px-rem(1) solid var(--pref-color-10)
            border-radius: 100%

            @include at-least($mobile)
                margin-left: px-rem(15)

            .cart > .cart-link,
            .cart-link-close
                display: flex
                align-items: center
                justify-content: center
                height: px-rem(60)
                width: px-rem(60)
                font-size: px-rem(22)
                color: var(--pref-color-RV)

                .cnt
                    position: absolute
                    top: 0
                    right: 0
                    display: flex
                    align-items: center
                    justify-content: center
                    border-radius: 100%
                    background-color: var(--brand-color)
                    height: 1.5em
                    width: 1.5em
                    font-size: px-rem(12)
                    color: white

            .hamburger
                display: block
                padding: calc(calc(#{px-rem(60)} - var(--hamburger-height)) / 2) calc(calc(#{px-rem(60)} - var(--hamburger-width)) / 2)