/**
 * ========== ABSTRACTS
 *
 * all Sass tools and helpers used across the project.
 * Every global variable, function, mixin and placeholder should be put in here.
 */
@use 'abstracts' as *
/**
 * ============================================================
 */



/**
 * ========== WP Block STYLES
 *
 * Description Pending
 */
// @import url('https://**/wp-includes/css/dist/block-library/style.css'
/**
 * ============================================================
 */



/**
 * ========== VENDORS
 *
 * The CSS files from external libraries and frameworks
 */
@use 'vendors'
/**
 * ============================================================
 */



 /**
  * ========== FRAMEWORK
  *
  * all Sass tools and helpers used across the project.
  * Every global variable, function, mixin and placeholder should be put in here.
  */
@use 'frameworks'
/**
  * ============================================================
  */



 /**
  * ========== BASE
  *
  * The boilerplate code for the project. In there, you might find some typographic
  * rules, and probably a stylesheet defining some standard styles for commonly
  * used HTML elements.
  *
  */
@use 'base'
/**
  * ============================================================
  */



/**
 * ========== COMPONENTS
 *
 * For smaller components, there is the components folder.
 * While layout/ is macro (defining the global wireframe), components/ is more
 * focused on widgets. It contains all kind of specific modules like a slider,
 * a loader, a widget, and basically anything along those lines. There are usually
 * a lot of files in components/ since the whole site/application should be mostly
 * composed of tiny modules.
 */
@use 'components'
/**
 * ============================================================
 */



/**
 * ========== LAYOUT
 *
 * Everything that takes part in laying out the site or application.
 * This folder could have stylesheet for the main parts of the site (header,
 * footer, navigation, sidebar…), the flexbox or even CSS styles
 * for all the forms.
 */
@use 'layout'
/**
 * ============================================================
 */



/**
 * ========== WooCommerce
 *
 * For smaller components, there is the components folder.
 * While layout/ is macro (defining the global wireframe), components/ is more
 * focused on widgets. It contains all kind of specific modules like a slider,
 * a loader, a widget, and basically anything along those lines. There are usually
 * a lot of files in components/ since the whole site/application should be mostly
 * composed of tiny modules.
 */
//@use 'woocommerce'
/**
 * ============================================================
 */



/**
 * ========== Animations
 *
 * Any CSS animations such as those that use @keyframes should be stored in here.
 * Feel free to create file for each complex animation but if's something that
 * take about 10 ~ 20 lines (roughly, not a hard line) try and store it in animations
 * file. Once created a new file don't forget to add it to the _+animations.scss order.
 */
@use 'animations'
/**
 * ============================================================
 */



/**
 * ========== Hard Overrides
 *
 * To override any of the styles above use the space below
 */

html
    scroll-behavior: smooth

*,
*:before,
*:after
    box-sizing: border-box

    @if $debug == false
        cursor: none !important

img
    display: block
    max-width: 100%
    height: auto

.emoji
    font-weight: 100

// WP Admin Bar FrontEnd Fixes

#wpadminbar ul#wp-admin-bar-root-default>li.wlcms-admin-logo
    border: solid 5px #39b54a
    border-top: none
    border-bottom: none

#wpadminbar a, #wpadminbar a img
    width: auto
    height: 100%

#adminmenu li.wp-menu-separator
    margin: 6px 0
    background: #343434

#fader
    background-color: var(--pref-color-RV)
    color: white

    .in-memory-of-queen
        .img-wrap
            max-width: 64%
            margin-inline: auto
            position: relative
            overflow: hidden
            border: solid 1rem black

            &::before
                content: ""
                position: absolute
                top: 0
                right: 0
                z-index: 2
                width: 2rem
                height: 20rem
                border: solid 0.2rem black
                transform: rotate(-45deg) translate(200%, -20%)
                background-color: var(--pref-color-RV)

            img
                position: relative
                z-index: 1
                margin-bottom: 1rem
                margin-bottom: 0

        .h1
            font-family: serif
            text-align: center

        .line-1,
        .line-2
            font-weight: 800

        .line-3
            font-weight: 300