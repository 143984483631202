@use '../abstracts' as *
    
.designed-by-modal
    display: none
    align-items: center
    justify-content: center
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 9999999999
    color: var(--brand-color)
    background-color: rgba(0, 0, 0, 0.5)
    transition: all 0.3s ease-in-out
    opacity: 0

    &.is-active
        display: flex

    &.is-visible
        opacity: 1

    > .close-overlay
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        z-index: 1

    .modal-content
        position: relative
        z-index: 2
        max-width: 80%
        max-height: 80%
        width: 100%
        height: 100%
        background-color: white
        border: 0.5rem solid var(--brand-color-secondary)
        border-radius: 2rem

        > .close-overlay
            position: absolute
            top: 1rem
            right: 1rem

        .modal-title
            text-align: center
            text-transform: uppercase
            margin: 1rem

        .team-type
            text-align: center

    .item-gf-team-member
        width: 80%
        margin: auto
        text-align: center

        .item-img
            width: 10vw
            height: 10vw
            margin: auto
            border: 0.4rem solid var(--brand-color-secondary)
            border-radius: 100%
            overflow: hidden

            img
                width: 100%
                height: 100%

        .meta-member-name
            margin-top: 0.5rem
            font-size: px-rem(18)

        .meta-member-role
            font-size: px-rem(14)

    .section-item
        text-align: center