@use '../abstracts' as *;

@mixin dark-mode {
    @media (prefers-color-scheme: dark) {
        :root {
            @content;
        }
    }

    [pref-color="dark"] {
        @content;
    }
}

@mixin light-mode {
    @media (prefers-color-scheme: light) {
        :root {
            @content;
        }
    }

    [pref-color="light"] {
        @content;
    }
}

:root {
    // VCs (Light Steps)
    @for $size from 0 to 101 {
        --pref-color-light-#{$size}: #{darken($full-light, $size)};
    }

    // VCs (Light Steps Transparentize)
    @for $size from 0 to 101 {
        --pref-color-light-t-#{$size}: #{transparentize($full-light, div($size, 100))};
    }
    
    // VCs (Dark Steps)
    @for $size from 0 to 101 {
        --pref-color-dark-#{$size}: #{lighten($full-dark, $size)};
    }
    
    // VCs (Dark Steps Transparentize)
    @for $size from 0 to 101 {
        --pref-color-dark-t-#{$size}: #{transparentize($full-dark, div($size, 100))};
    }

    --pref-color-wt: #{$full-light};
    --pref-color-bk: #{$full-dark};
}
    
@include dark-mode {
    // VCs (Dark Steps)
    @for $size from 0 to 101 {
        --pref-color-#{$size}: var(--pref-color-dark-#{$size});
    }

    // VC Child Pref Color Intensity Grow Step (AUto)
    --pref-color-AU: #{transparentize(lighten($full-dark, 99), 0.99)};
    // VS Full Pref Reverse
    --pref-color-RV: #{$full-light};
    // VS Full Pref Shadow
    --pref-color-shadow: var(--pref-color-20);
    // VS Full Pref Neumorphic Shadow
    --pref-color-nm-shadow: var(--pref-color-dark-t-0);
    // VS Full Pref Neumorphic Shadow weak
    --pref-color-nm-shadow-weak: var(--pref-color-dark-t-50);
    // VS Full Pref Neumorphic Light Shadow
    --pref-color-nm-glow: var(--pref-color-light-t-90);
    // VS Full Pref Neumorphic Light Shadow weak
    --pref-color-nm-glow-weak:   var(--pref-color-light-t-90);
    // VS Full Pref Neumorphic Rim color
    --pref-color-nm-rim: var(--pref-color-2);
    // VS Full Pref Neumorphic outward shadow
    --nm-outset:
        #{px-rem(3)} #{px-rem(-3)} #{px-rem(12)} var(--pref-color-nm-glow),
        #{px-rem(-6)} #{px-rem(6)} #{px-rem(12)} var(--pref-color-nm-shadow);
    --ds-nm-outset:
        drop-shadow(#{px-rem(3)} #{px-rem(-3)} #{px-rem(12)} var(--pref-color-nm-glow))
        drop-shadow(#{px-rem(-6)} #{px-rem(6)} #{px-rem(12)} var(--pref-color-nm-shadow));
    
    // VS Full Pref Neumorphic outward shadow (Small)
    --nm-outset-small:
        #{px-rem(1.5)} #{px-rem(-1.5)} #{px-rem(6)} var(--pref-color-nm-glow),
        #{px-rem(-3)} #{px-rem(3)} #{px-rem(6)} var(--pref-color-nm-shadow);
    --ds-nm-outset-small:
        drop-shadow(#{px-rem(1.5)} #{px-rem(-1.5)} #{px-rem(6)} var(--pref-color-nm-glow))
        drop-shadow(#{px-rem(-3)} #{px-rem(3)} #{px-rem(6)} var(--pref-color-nm-shadow));
    
    // VS Full Pref Neumorphic inward shadow
    --nm-inset:
        inset #{px-rem(3)} #{px-rem(-3)} #{px-rem(12)} var(--pref-color-nm-glow),
        inset #{px-rem(-6)} #{px-rem(6)} #{px-rem(12)} var(--pref-color-nm-shadow);
    --ds-nm-inset:
        drop-shadow(inset #{px-rem(3)} #{px-rem(-3)} #{px-rem(12)} var(--pref-color-nm-glow))
        drop-shadow(inset #{px-rem(-6)} #{px-rem(6)} #{px-rem(12)} var(--pref-color-nm-shadow));
    
    // VS Full Pref Neumorphic inward Out shadow
    --nm-inset-out:
        inset #{px-rem(-3)} #{px-rem(3)} #{px-rem(12)} var(--pref-color-nm-glow-weak),
        inset #{px-rem(6)} #{px-rem(-6)} #{px-rem(12)} var(--pref-color-nm-shadow),
        #{px-rem(-3)} #{px-rem(3)} #{px-rem(6)} var(--pref-color-nm-shadow-weak);
    --ds-nm-inset-out:
        drop-shadow(inset #{px-rem(-3)} #{px-rem(3)} #{px-rem(12)} var(--pref-color-nm-glow-weak))
        drop-shadow(inset #{px-rem(6)} #{px-rem(-6)} #{px-rem(12)} var(--pref-color-nm-shadow))
        drop-shadow(#{px-rem(-3)} #{px-rem(3)} #{px-rem(6)} var(--pref-color-nm-shadow-weak));
    
    // VS Full Pref Neumorphic inward (Small)
    --nm-inset-small:
        inset #{px-rem(3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-glow),
        inset #{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-shadow);
    --ds-nm-inset-small:
        drop-shadow(inset #{px-rem(3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-glow))
        drop-shadow(inset #{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-shadow));
    
    // VS Full Pref Neumorphic inward Out (Small)
    --nm-inset-out-small:
        inset #{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-glow-weak),
        inset #{px-rem(3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-shadow),
        #{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(3)} var(--pref-color-nm-shadow-weak);
    --ds-nm-inset-out-small:
        drop-shadow(inset #{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-glow-weak))
        drop-shadow(inset #{px-rem(3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-shadow))
        drop-shadow(#{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(3)} var(--pref-color-nm-shadow-weak));

    // VC Main BG Color
    --main-bg-color:     var(--brand-color);
    // VC Main BG Color Alt
    --main-bg-color-alt: var(--pref-color-5);

    // VTCs (Variable Text Colors)
    --text-color: var(--pref-color-dark-0);
    --link-color: var(--brand-color);
}


@include light-mode {
    // VCs (Light Steps)
    @for $size from 0 to 101 {
        --pref-color-#{$size}: var(--pref-color-light-#{$size});
    }

    // VC Child Pref Color Intensity Grow Step (AUto)
    --pref-color-AU:             #{transparentize(darken($full-light, 99), 0.99)};
    // VS Full Pref Reverse
    --pref-color-RV:             #{$full-dark};
    // VS Full Pref Shadow
    --pref-color-shadow:         var(--pref-color-20);
    // VS Full Pref Neumorphic Shadow
    --pref-color-nm-shadow:      var(--pref-color-dark-t-88);
    // VS Full Pref Neumorphic Shadow Weak
    --pref-color-nm-shadow-weak:      var(--pref-color-dark-t-80);
    // VS Full Pref Neumorphic Light Shadow
    --pref-color-nm-glow:        var(--pref-color-light-t-20);
    // VS Full Pref Neumorphic Light Shadow
    --pref-color-nm-glow-weak:   var(--pref-color-light-t-60);
    // VS Full Pref Neumorphic Rim color
    --pref-color-nm-rim:         var(--pref-color-2);

    // VS Full Pref Neumorphic outward shadow
    --nm-outset:
        #{px-rem(6)} #{px-rem(6)} #{px-rem(12)} var(--pref-color-nm-shadow),
        #{px-rem(0)} #{px-rem(0)} #{px-rem(12)} #{px-rem(6)} var(--pref-color-nm-glow);
    --ds-nm-outset:
        drop-shadow(#{px-rem(6)} #{px-rem(6)} #{px-rem(12)} var(--pref-color-nm-shadow))
        drop-shadow(#{px-rem(-6)} #{px-rem(-6)} #{px-rem(12)} var(--pref-color-nm-glow));

    // VS Full Pref Neumorphic outward shadow (Small)
    --nm-outset-small:
        #{px-rem(3)} #{px-rem(3)} #{px-rem(6)} var(--pref-color-nm-shadow),
        #{px-rem(0)} #{px-rem(0)} #{px-rem(6)} #{px-rem(3)} var(--pref-color-nm-glow);
    --ds-nm-outset-small:
        drop-shadow(#{px-rem(3)} #{px-rem(3)} #{px-rem(6)} var(--pref-color-nm-shadow))
        drop-shadow(#{px-rem(0)} #{px-rem(0)} #{px-rem(6)} var(--pref-color-nm-glow));

    // VS Full Pref Neumorphic outward shadow (Small)
    --nm-outset-xsmall:
        #{px-rem(1.5)} #{px-rem(1.5)} #{px-rem(3)} var(--pref-color-nm-shadow),
        #{px-rem(0)} #{px-rem(0)} #{px-rem(3)} #{px-rem(1.5)} var(--pref-color-nm-glow);
    --ds-nm-outset-xsmall:
        drop-shadow(#{px-rem(1.5)} #{px-rem(1.5)} #{px-rem(3)} var(--pref-color-nm-shadow))
        drop-shadow(#{px-rem(0)} #{px-rem(0)} #{px-rem(3)} var(--pref-color-nm-glow));

    // VS Full Pref Neumorphic inward shadow
    --nm-inset:
        inset #{px-rem(-3)} #{px-rem(-3)} #{px-rem(12)} var(--pref-color-nm-glow),
        inset #{px-rem(6)} #{px-rem(6)} #{px-rem(12)} var(--pref-color-nm-shadow);
    --ds-nm-inset:
        drop-shadow(inset #{px-rem(-3)} #{px-rem(-3)} #{px-rem(12)} var(--pref-color-nm-glow))
        drop-shadow(inset #{px-rem(6)} #{px-rem(6)} #{px-rem(12)} var(--pref-color-nm-shadow));

    // VS Full Pref Neumorphic inward Out shadow
    --nm-inset-out:
        inset #{px-rem(-3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-shadow),
        inset #{px-rem(1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-glow-weak),
        #{px-rem(1.5)} #{px-rem(1.5)} #{px-rem(3)} var(--pref-color-nm-shadow-weak);
    --ds-nm-inset-out:
        drop-shadow(inset #{px-rem(-3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-shadow))
        drop-shadow(inset #{px-rem(1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-glow-weak))
        drop-shadow(#{px-rem(1.5)} #{px-rem(1.5)} #{px-rem(3)} var(--pref-color-nm-shadow-weak));

    // VS Full Pref Neumorphic inward (Small)
    --nm-inset-small:
        inset #{px-rem(-3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-glow),
        inset #{px-rem(1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-shadow);
    --ds-nm-inset-small:
        drop-shadow(inset #{px-rem(3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-glow))
        drop-shadow(inset #{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-shadow));

    // VS Full Pref Neumorphic inward Out (Small)
    --nm-inset-out-small:
        inset #{px-rem(-1.5)} #{px-rem(-1.5)} #{px-rem(3)} var(--pref-color-nm-shadow),
        inset #{px-rem(0.75)} #{px-rem(0.75)} #{px-rem(3)} var(--pref-color-nm-glow-weak),
        #{px-rem(0.75)} #{px-rem(0.75)} #{px-rem(1.5)} var(--pref-color-nm-shadow-weak);
    --ds-nm-inset-out-small:
        drop-shadow(inset #{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(6)} var(--pref-color-nm-glow-weak))
        drop-shadow(inset #{px-rem(3)} #{px-rem(-3)} #{px-rem(6)} var(--pref-color-nm-shadow))
        drop-shadow(#{px-rem(-1.5)} #{px-rem(1.5)} #{px-rem(3)} var(--pref-color-nm-shadow-weak));

    // VC Main BG Color
    --main-bg-color:     var(--brand-color);
    // VC Main BG Color Alt
    --main-bg-color-alt: var(--pref-color-5);

    // VTCs (Variable Text Colors)
    --text-color: var(--pref-color-RV);
    --link-color: var(--brand-color);
}



// Mobile (ROOT) Variables
:root {
    // TFs (Theme Fonts)
    --font-size:   #{px-vw(map-get(map-get($design-sizes-map, mobile), fs), m)};
    --line-height: #{px-em(map-get(map-get($design-sizes-map, mobile), lh), map-get(map-get($design-sizes-map, mobile), fs))};

    --font-family:       'Inter', sans-serif;
    --font-family-icons: 'icons';
    
    --headings-font-family: var(--font-family);
    --headings-font-weight: 500;
    --headings-line-height: 1.3;

    // RVCs (Reserved Variable colours)

    --color-1:  rgb(255, 255, 255);
    --color-2:  rgb(0, 0, 0);
    --color-3:  rgb(30, 60, 113);
    --color-4:  rgb(104, 169, 220);
    --color-5:  #{darken(rgb(30, 60, 113), 10)};
    --color-6:  #{darken(rgb(104, 169, 220), 10)};
    --color-7:  rgb(0, 0, 0);
    --color-8:  rgb(0, 0, 0);
    --color-9:  rgb(0, 0, 0);
    --color-10: rgb(0, 0, 0);

    --color-11: rgb(0, 168, 255);
    --color-12: rgb(0, 151, 230);
    --color-13: rgb(156, 136, 255);
    --color-14: rgb(140, 122, 230);
    --color-15: rgb(251, 197, 49);
    --color-16: rgb(225, 177, 44);
    --color-17: rgb(76, 209, 55);
    --color-18: rgb(68, 189, 50);
    --color-19: rgb(72, 126, 176);
    --color-20: rgb(64, 115, 158);
    --color-21: rgb(232, 65, 24);
    --color-22: rgb(194, 54, 22);
    --color-23: rgb(245, 246, 250);
    --color-24: rgb(220, 221, 225);
    --color-25: rgb(127, 143, 166);
    --color-26: rgb(113, 128, 147);
    --color-27: rgb(39, 60, 117);
    --color-28: rgb(25, 42, 86);
    --color-29: rgb(53, 59, 72);
    --color-30: rgb(47, 54, 64);

    --pref-color-WT: #{$full-light};
    --pref-color-BK: #{$full-dark};

    // VBCs (Variable Brand Colors)
    --brand-color:               var(--color-3);
    --brand-color-alt:           var(--color-5);
    --brand-color-secondary:     var(--color-4);
    --brand-color-secondary-alt: var(--color-6);

    --button-color:      var(--brand-color);
    --button-text-color: var(--color-1);
    --headings-color:       inherit;

    // VSS (Viewport & Screen Sizes)
    --s-mobile:  #{$s-mobile};
    --m-mobile:  #{$m-mobile};
    --l-mobile:  #{$l-mobile};
    --s-tablet:  #{$s-tablet};
    --m-tablet:  #{$m-tablet};
    --l-tablet:  #{$l-tablet};
    --s-laptop:  #{$s-laptop};
    --m-laptop:  #{$m-laptop};
    --l-laptop:  #{$l-laptop};
    --s-desktop: #{$s-desktop};
    --m-desktop: #{$m-desktop};
    --l-desktop: #{$l-desktop};

    //// Cvw (Center ViewportWidth)
    @each $label_i, $map_i in $center-vw-map {
        --centre-vw-#{$label_i}: #{px-vw(map-get($map_i, mobile), m)};
    }

    
    // DCSs (Design Column Settings)
    --flex-gutter: #{px-rem(20, m)};
    
    //Header Options
    --header-height:       #{px-rem(96, m)};
    --header-small-height: #{px-rem(60, m)};

    // Hamburger
    --hamburger-height:      #{px-rem(24, m)};
    --hamburger-width:       #{px-rem(28.19, m)};
    --hamburger-line-height: #{px-rem(4, m)};
    --hamburger-translateY:  (var(--hamburger-height) - var(--hamburger-line-height)) / 2;

    --mobile-break: #{$mobile};
    --tablet-break: #{$tablet};
}



// Tablet Specific Root Vars
@media only screen and (min-width: $l-mobile) {
    :root {
        // Cvw (Center ViewportWidth) 
    }
}



// Mobile (ROOT) Variables
@include at-least($mobile) {
    :root {
        // TFSs (Theme Fonts Sizes)
        --font-size:   #{px-vw(map-get(map-get($design-sizes-map, desktop), fs), d)};
        --line-height: #{px-em(map-get(map-get($design-sizes-map, desktop), lh), map-get(map-get($design-sizes-map, desktop), fs))};

        //// Cvw (Center ViewportWidth)
        @each $label_i, $map_i in $center-vw-map {
            --centre-vw-#{$label_i}: #{px-vw(map-get($map_i, desktop), d)};
        }

        // Header
        --header-height: #{px-rem(72, d)};
        --header-small-height: #{px-rem(100)};

        // Hamburger
        --hamburger-height:      #{px-rem(30, d)};
        --hamburger-width:       #{px-rem(38, d)};
        --hamburger-line-height: #{px-rem(4, d)};
        --hamburger-translateY:  (var(--hamburger-height) - var(--hamburger-line-height)) / 2;
    }
}