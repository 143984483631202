@use '../abstracts' as *

@mixin label
    margin-bottom: px-em(30, 23)
    width: 100%
    max-width: 100%

    line-height: px-em(36, 23)

    color: var(--text-color)

@mixin input
    font-size: px-rem(25)

    @include placeholder
        // user input text inherits properties from placeholder!
        font-size: px-rem(25)
        line-height: px-em(68, 25)
        padding: 0 px-em(10, 25)
        font-weight: 400
        color: var(--text-color)
        opacity: 0.2

    border: solid px-rem(1) var(--color-1)
    border-radius: px-em(10, 25)
    margin: 0
    width: 100%
    max-width: 100%

    font-family: var(--font-family)

    outline: none
    -webkit-appearance: none

    color: var(--text-color)
    background-color: #fff
    opacity: 1

label
    @include label

// %input {
//     padding: 0 px-em(3, 14);
//     padding-top: px-em(4, 14);

//     color: var(--text-color);
//     font-family: var(--font-family);
//     font-size: px-rem(16);

//     outline: none;
//     -webkit-appearance: none;
//     background-color: var(--brand-color);

//     @include at-least(var(--l-tablet) ){
//         font-size: px-rem(14);
//     }
// }

// %cf7-input {
//     max-width: 100%;
//     font-size: px-rem(16);
//     line-height: calc(52 / 16);
//     border: none;
//     padding: 0 px-em(30, 16) 0 px-em(30, 16);
//     border: px-em(2, 16) solid var(--color-5);
//     border-radius: px-em(26, 16);

//     color: var(--text-color);
//     font-family: var(--font-family-alt);
//     font-style: italic;

//     color: var(--color-7);
//     background-color: var(--brand-color);
//     outline: none;
//     -webkit-appearance: none;
//     transition: all 0.3s ease-in-out;
// }


input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea
    @include input

    &:focus
        border-color: #999999
        outline: none

fieldset
    display: block
    padding: px-em(20, 16)
    margin-inline-start: px-em(2, 16)
    margin-inline-end: px-em(2, 16)
    min-inline-size: min-content
    border: solid px-em(2, 16) var(--color-5)
    border-radius: 0

textarea
    line-height: 1.5
    min-height: 118px
    padding: 10px 12px

select
    padding: px-rem(8) px-rem(15)
    -webkit-appearance: none
    background-color: transparent
    border-radius: 0
    color: var(--text-color)
    font-family: var(--font-family)
    line-height: var(--line-height)
    outline: none

.select2-container--default .select2-selection--single
    border: px-em(1.5, 14) solid var(--color-5)
    border-radius: 0rem
    background-color: var(--color-1)

.select2-container .select2-selection--single
    height: auto

.select2-container--default .select2-selection--single .select2-selection__rendered
    font-family: var(--font-family-base)
    line-height: var(--line-height-base)
    font-size: px-rem(14)
    line-height: calc(22 / 14)
    color: var(--text-color)

.select2-container--default .select2-selection--single .select2-selection__arrow
    height: 1em
    top: 50%
    transform: translateY(-50%)
    right: 0.5em
    width: 1em

.search-bar
    position: relative
    max-width: 17.5rem
    width: 100%
    margin-right: 3.75rem
    color: white
    
    @include placeholder
        color: rgba(255, 255, 255, 0.5)
        line-height: 1
        // @include font-size(16)
    
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration
        display: none

    input[type=search]
        width: 100%
        line-height: calc(39 / 16)
        padding: 0 0.875em
        
        border: none
        border-radius: 2.06rem

        font-size: px-rem(16)
        font-family: var(--font-family-base)
        color: white
        background-color: var(--color-10)

    button[type=submit]
        position: absolute
        top: 50%
        right: 0
        transform: translateY(-50%)
        border: none
        padding: 0
        // @include font-size(20)
        line-height: calc(39 / 20)
        margin-right: 0.5em

        background-color: transparent

        transition: all 0.3s ease-in-out

        &:hover
            background-color: transparent
            opacity: 0.5

.wpcf7
    .screen-reader-response
        display: none !important

    .wpcf7-not-valid-tip
        display: block

    .wpcf7-validation-errors
        color: #b60005
