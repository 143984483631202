@use '../abstracts' as *

.tabs
    .tabs-nav
        background-color: rgba(var(--brand-color), 0.8)
        margin: 0
        padding: 0
        list-style: none
        text-transform: uppercase
        display: flex
        justify-content: space-around

        li
            flex-basis: 0
            flex-grow: 1
            position: relative

            &:before
                position: absolute
                left: 0
                top: 0
                bottom: 0
                content: ""
                width: 1px
                background-color: white

            &:first-child:before
                display: none

        .tabs-link
            color: #fff
            display: block
            padding: 20px
            font-size: 18px
            text-align: center
            background-color: var(--brand-color-secondary)
            transition: background-color 0.3s ease-in-out

            &.is-active
                background-color: var(--brand-color)

                &:hover
                    background-color: var(--color-4)

            &:hover
                background-color: var(--color-5)

    .tab-content
        display: none
        padding: 20px 0
        background-color: var(--brand-color)
        color: white

        &.is-active
            display: block
