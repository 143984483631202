@use '../base/icons' as *
@use '../abstracts' as *

@mixin button-hover
    background-color: var(--brand-color-alt)
    color: #fff


@mixin button
    display: inline-block
    padding: 0 px-em(32, 16)
    border: none
    border-radius: px-em(48, 16)

    outline: 0
    cursor: pointer

    font-size: px-rem(16)
    line-height: px-em(44, 16)
    text-transform: capitalize
    text-align: center
    text-decoration: none
    font-weight: 700

    color: var(--button-text-color)
    background-color: var(--button-color)

    @if $nm-design == true
        box-shadow: var(--nm-inset-out)

    transition: all 0.3s ease-in-out

    &:disabled
        background-color: var(--brand-color-alt)
        color: var(--color-1)
        cursor: no-drop

    &:focus
        outline: solid var(--brand-color) px-em(2, 16)
        outline-offset: px-em(4, 16)

    &:hover
        @include button-hover


@mixin button-alt-hover
    @include button-hover
    background-color: transparent
    border: px-em(2, 16) solid var(--brand-color-alt)
    color: var(--brand-color-alt)


@mixin button-alt
    @include button
    border: px-em(2, 16) solid var(--brand-color)
    background-color: transparent
    color: var(--brand-color)
    
    &:hover
        @include button-alt-hover

    &:disabled
        background-color: transparentize(black, 0.9)
        border: px-em(2, 16) solid var(--brand-color)
        color: var(--brand-color)

    &:focus
        outline: solid var(--brand-color) px-em(2, 16)
        outline-offset: px-em(4, 16)


@mixin button-secondary-hover
    background-color: var(--brand-color-secondary-alt)
    color: var(--color-1)


@mixin button-secondary
    @include button
    background-color: var(--brand-color-secondary)
    color: var(--color-1)
    border: none
    
    &:hover
        @include button-secondary-hover

    &:disabled
        background-color: var(--brand-color-secondary-alt)
        color: darken(white, 10)
        border: none

    &:focus
        outline: solid var(--brand-color-secondary-alt) px-em(2, 16)
        outline-offset: px-em(4, 16)


@mixin button-secondary-alt-hover
    @include button-secondary-hover
    background-color: transparent
    border-color: var(--brand-color-secondary-alt)
    color: var(--brand-color-secondary-alt)


@mixin button-secondary-alt
    @include button-secondary
    background-color: transparent
    border: px-em(2, 16) solid var(--brand-color-secondary)
    color: var(--brand-color-secondary)

    &:hover
        @include button-secondary-alt-hover

    &:disabled
        background-color: transparentize(black, 0.9)
        border: px-em(2, 16) solid var(--brand-color-secondary-alt)
        color: var(--brand-color-secondary-alt)

    &:focus
        outline: solid var(--brand-color-secondary-alt) px-em(2, 16)
        outline-offset: px-em(4, 16)


@mixin button-arrow-hover
    @include button-secondary-hover
    background-color: transparent
    border: none
    color: var(--brand-color-alt)

    &:after
        margin-left: px-em(15, 16)


@mixin button-arrow
    @include button
    padding: 0
    line-height: px-em(21, 16)
    background-color: transparent
    color: var(--color-3)
    border: none
    font-weight: 700
    
    &:after
        content: map-get($icons-map, "arrow-right")
        @include font-icon
        margin-left: px-em(8, 16)
        transition: all 0.3s ease-in-out
    
    &:hover
        @include button-arrow-hover

    &:disabled
        background-color: transparent
        border: px-em(2, 16) solid var(--color-10)


input[type="submit"],
input[type="button"],
input[type="reset"],
button,
[class*=button]
    @include button


    &.button-alt
        @include button-alt


    &.button-secondary
        @include button-secondary


    &.button-secondary-alt
        @include button-secondary-alt


    &.button-arrow
        @include button-arrow

    &.button-scroll-down
        padding: 0
        background-color: transparent
        color: var(--pref-color-RV)
        text-transform: uppercase
        font-weight: 500
        font-size: px-rem(15, m)

        @include at-least($mobile)
            font-size: px-rem(15)

        &::after
            @include font-icon
            content: map-get($icons-map, "arrow-down")
            font-weight: px-em(18, 15)
            margin-left: px-em(27, 15)
