@use '../abstracts' as *;

@mixin flexbox-position {
    list-style: none;
    padding-left: 0;

    &.flush-outline {
        padding: calc(-1 * var(--flex-gutter));
    }

    &.flow-column { flex-flow: column; }
    &.flow-row { flex-flow: row; }

    &.flow-column-reverse { flex-flow: column-reverse; }
    &.flow-row-reverse {  flex-flow: row-reverse; }

    &.align-start { align-items: flex-start; }
    &.align-center { align-items: center; }
    &.align-stretch { align-items: stretch; }
    &.align-end { align-items: flex-end; }

    &.justify-start { justify-content: flex-start; }
    &.justify-center { justify-content: center; }
    &.justify-stretch { justify-content: stretch; }
    &.justify-end { justify-content: flex-end; }
    &.justify-space-around { justify-content: space-around; }
    &.justify-space-between { justify-content: space-between; }
    &.justify-space-evenly { justify-content: space-evenly; }

    &.no-wrap {
        flex-wrap: nowrap;
    }

    &.m-flow-column { @include until($mobile) { flex-flow: column; } }
    &.m-flow-row { @include until($mobile) { flex-flow: row; } }

    &.m-flow-column-reverse { @include until($mobile) { flex-flow: column-reverse; } }
    &.m-flow-row-reverse {  @include until($mobile) { flex-flow: row-reverse; } }

    &.m-align-start { @include until($mobile) { align-items: flex-start; } }
    &.m-align-center { @include until($mobile) { align-items: center; } }
    &.m-align-stretch { @include until($mobile) { align-items: stretch; } }
    &.m-align-end { @include until($mobile) { align-items: flex-end; } }

    &.m-justify-start { @include until($mobile) { justify-content: flex-start; } }
    &.m-justify-center { @include until($mobile) { justify-content: center; } }
    &.m-justify-stretch { @include until($mobile) { justify-content: stretch; } }
    &.m-justify-end { @include until($mobile) { justify-content: flex-end; } }
    &.m-justify-space-around { @include until($mobile) { justify-content: space-around; } }
    &.m-justify-space-between { @include until($mobile) { justify-content: space-between; } }
    &.m-justify-space-evenly { @include until($mobile) { justify-content: space-evenly; } }
    
    &.m-wrap { @include until($mobile) { flex-wrap: wrap; } }

    &.full-width { width: 100%; }
}

[class^="flex-"],
[class*=" flex-"] {
    padding: var(--flex-gutter);
}

.flex {
    display: flex;
    flex-flow: row wrap;
    margin: 0;

    @include flexbox-position;

    > [class^="flex-"],
    > [class*=" flex-"] {
        padding: 0;
    }

    @for $size from 1 to 100 {
        &.gap-#{$size} {
            margin-inline: px-rem(div($size, 2) * -1);

            > [class^="flex-"],
            > [class*=" flex-"] {
                padding: px-rem(div($size, 2));
            }

            &.gap-no-top { margin-top: px-rem(div($size, 2) * -1); }

            &.gap-no-bottom { margin-bottom: px-rem(div($size, 2) * -1); }
        }
    }
}

.flexbox {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin: 0 calc(-1 * var(--flex-gutter));

    @include flexbox-position;
}

// Removed flexbox on mobile
// Useful when need to make slideshow on mobile/desktop
.flexbox-desktop {
    @include at-least($mobile) {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        margin: 0 calc(-1 * var(--flex-gutter));
    }

    @include flexbox-position;
}

.woocommerce-product-gallery {
    [class^="flex-"],
    [class*=" flex-"] {
        padding: initial;
    }
}


@each $flex in $screen-sizes {
    $key: nth($flex, 1);   // Screen Size
    $value: nth($flex, 2); // Screen Size value

    @if($key != "") { // If not Mobile
        @include until($value) {
            [class*=offset-] {
                width: 100%;
                margin: 0;
            }
        }

        @include at-least($value) {
            @for $i from 1 through $flex-columns {
                .flex-#{$key}#{$i} {
                    width: calc(100% / (#{$flex-columns} / #{$i}));
                }
            
                .offset-#{$key}#{$i} {
                    margin-left: calc(100% / (#{$flex-columns} / #{$i}));
                }
            }
        }
    } @else { // If Mobile
        @include until($value) {
            [class*=offset-] {
                width: 100%;
                margin: 0;
            }
        }

        @for $i from 1 through $flex-columns {
            .flex-#{$i} {
                width: calc(100% / (#{$flex-columns} / #{$i}));
            }
        
            .offset-#{$i} {
                margin-left: calc(100% / (#{$flex-columns} / #{$i}));
            }
        }
    }
}
