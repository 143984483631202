@use '../abstracts' as *
	
.control
	position: relative
	display: inline-block
	padding-left: px-em(30, 16)
	margin-bottom: px-em(15, 16)
	cursor: pointer
	font-size: px-em(18, 16)

	input
		position: absolute
		z-index: -1
		opacity: 0

		&:checked
			& ~ .control__indicator
				background: var(--color-5)

				&:after
					display: block
        
		&:disabled
			& ~ .control__indicator
				background: var(--color-3)
				opacity: 0.6
				pointer-events: none

.control__indicator
	position: absolute
	top: px-em(2, 16)
	left: 0
	height: px-em(20, 16)
	width: px-em(20, 16)
	background: var(--color-3)

	&:after
		content: ''
		position: absolute
		display: none

.control--radio
	.control__indicator
		border-radius: 50%

		&:after
			left: 50%
			top: 50%
			transform: translate(-50%, -50%)
			height: px-em(6, 16)
			width: px-em(6, 16)
			border-radius: 50%
			background: #fff

	input
		&:disabled
			& ~ .control__indicator
				&:after
					background: var(--color-5)

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator
	background: var(--color-5)

.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator
	background: var(--color-5)

.control--checkbox
	.control__indicator
		&:after
			top: 50%
			left: 50%
			transform: translate(-50%, -50%) rotate(45deg)
			width: px-em(5, 16)
			height: px-em(10, 16)
			border: solid #fff
			border-width: 0 px-em(2, 16) px-em(2, 16) 0

	input
		&:disabled
			& ~ .control__indicator
				&:after
					border-color: var(--color-5)

.select
	position: relative
	display: inline-block
	width: 100%
	margin-bottom: px-em(15, 16)

	select
		display: inline-block
		width: 100%
		padding: px-em(10, 16) px-em(15, 16)
		border: 0
		border-radius: 0
		background: var(--color-3)
		color: var(--color-5)
		cursor: pointer
		outline: 0
		appearance: none
		-webkit-appearance: none
		-moz-appearance: none

		&::-ms-expand
			display: none

		&:disabled
			opacity: 0.5
			pointer-events: none

			& ~ .select__arrow
				border-top-color: #ccc

.select select:hover,
.select select:focus
	color: #000
	background: #ccc

.select__arrow
	position: absolute
	top: 50%
	right: px-em(15, 16)
	transform: translateY(-50%)
	width: 0
	height: 0
	border-style: solid
	border-width: px-em(8, 16) px-em(5, 16) 0 px-em(5, 16)
	border-color: var(--color-5) transparent transparent transparent
	pointer-events: none

.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow
	border-top-color: #000