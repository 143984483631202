@use '../abstracts' as *;

@mixin gridbox-position {

    $i: 0;
    @for $size from 0 to $flex-columns {
        $i: $i + 1;
        $j: null;
        &.grid-col-#{$i} {
            @for $size from 0 to $i { $j: $j 1fr; }
            grid-template-columns: $j;
        }
    }

}

.grid {
    display: grid;
    $grid-temp-col-count: null;
    @for $size from 0 to $flex-columns { $grid-temp-col-count: $grid-temp-col-count 1fr; }
    grid-template-columns: $grid-temp-col-count;

    @include gridbox-position;
}

@each $flex in $screen-sizes {
    $key: nth($flex, 1);   // Screen Size
    $value: nth($flex, 2); // Screen Size value

    // No Offset in Grid

    @if($key != "") { // If not Mobile
        @include at-least($value) {
            @for $i from 1 through $flex-columns {
                .grid-#{$key}#{$i} {
                    grid-column: span #{$i};
                }
            }

            .grid {
                @for $size from 0 to 101 {
                    &.gap-#{$key}#{$size} { gap: px-rem($size); }
            
                    &.gap-row-#{$key}#{$size} { row-gap: px-rem($size); }
            
                    &.gap-column-#{$key}#{$size} { column-gap: px-rem($size); }
                }
            }
        }
    } @else { // If Mobile

        @for $i from 1 through $flex-columns {
            .grid-#{$i} {
                grid-column: span #{$i};
            }
        }

        .grid {
            @for $size from 0 to 101 {
                &.gap-#{$size} { gap: px-rem($size); }
        
                &.gap-row-#{$size} { row-gap: px-rem($size); }
        
                &.gap-column-#{$size} { column-gap: px-rem($size); }
            }
        }
    }
}
