@use "sass:math"
@use '../abstracts' as *

.footer
    background-color: var(--brand-color)
    color: var(--color-1)
    font-size: px-rem(14, d)
    line-height: px-em(20, 14)

    a
        color: var(--color-1)

        &:hover
            color: var(--brand-color-secondary)

    .bottom-bar
        display: flex
        align-items: center
        min-height: px-rem(100, m)
        color: var(--color-1)
        text-align: center

        @include at-least($mobile)
            min-height: px-rem(68, d)

        .smallprint
            display: flex
            align-content: center
            flex-flow: row wrap
            color: var(--color-1)

            .bottom-bar-nav
                margin: 0 px-em(math.div(16, 2), 14)

                .menu
                    display: flex
                    justify-content: center
                    flex-flow: row wrap
                    margin: 0
                    color: var(--color-3)

                    .menu-item
                        text-transform: capitalize
                        margin: 0 px-em(math.div(16, 2), 14)

        .design-by-gws
            a
                display: flex
                align-items: center
                padding: px-em(10, 14, m) 0
                text-decoration: none

                @include at-least($mobile)
                    padding: 0

                span
                    color: var(--color-1)

                img
                    display: inline-block
                    margin-left: px-rem(10)
                    width: px-rem(100)