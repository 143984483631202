@use '../abstracts' as *
    
.accordion
    .accordion-group
        margin: 0

        &.is-active
            .accordion-panel
                max-height: px-rem(300)
                opacity: 1

            .accordion-title
                background-color: var(--brand-color-alt)

                &:before,
                &:after
                    transform: translateY(-50%) rotate(90deg)

        @include at-least($mobile)
            &:hover
                .accordion-panel
                    max-height: px-rem(300)
                    opacity: 1

                .accordion-title
                    background-color: var(--brand-color-alt)

                    &:before,
                    &:after
                        transform: translateY(-50%) rotate(90deg)

        .accordion-title
            background-color: var(--brand-color)
            color: var(--brand-color-secondary)
            position: relative
            border-bottom: px-rem(2) solid var(--brand-color-secondary)
            transition: all 0.3s ease-in-out

            &:before,
            &:after
                content: ""
                position: absolute
                top: 50%
                right: px-rem(15)
                transform: translateY(-50%) rotate(90deg)
                width: px-rem(2)
                height: px-rem(10)
                background-color: var(--brand-color-secondary)
                transition: all 0.3s ease-in-out
                cursor: pointer

            &:after
                transform: translateY(-50%)

        .accordion-title,
        .accordion-panel
            padding: px-rem(10)

            a
                display: block
                color: var(--brand-color-secondary)
                font-weight: 700

        .accordion-panel
            background-color: var(--color-1)
            border-left: px-rem(2) solid var(--brand-color-secondary)
            font-size: px-rem(15)

            max-height: 0px
            padding: 0
            overflow: hidden
            opacity: 0
            transition: all 0.3s ease-in-out

            .accordion-panel-inner
                padding: px-rem(10)