$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("../../../assets/fonts/icons.ttf?3504e7147808433f69eee83eb7e4ba8d") format("truetype"),
url("../../../assets/fonts/icons.woff?3504e7147808433f69eee83eb7e4ba8d") format("woff"),
url("../../../assets/fonts/icons.woff2?3504e7147808433f69eee83eb7e4ba8d") format("woff2");
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "facebook": "\f101",
    "instagram": "\f102",
    "linkedin": "\f103",
    "logo": "\f104",
    "pinterest": "\f105",
    "search": "\f106",
    "tel": "\f107",
    "twitter": "\f108",
    "youtube": "\f109",
);

.icon-facebook:before {
    content: map-get($icons-map, "facebook");
}
.icon-instagram:before {
    content: map-get($icons-map, "instagram");
}
.icon-linkedin:before {
    content: map-get($icons-map, "linkedin");
}
.icon-logo:before {
    content: map-get($icons-map, "logo");
}
.icon-pinterest:before {
    content: map-get($icons-map, "pinterest");
}
.icon-search:before {
    content: map-get($icons-map, "search");
}
.icon-tel:before {
    content: map-get($icons-map, "tel");
}
.icon-twitter:before {
    content: map-get($icons-map, "twitter");
}
.icon-youtube:before {
    content: map-get($icons-map, "youtube");
}
