@use '../abstracts' as *
    
html,
body
    font-family: var(--font-family)
    font-size: var(--font-size)
    line-height: var(--line-height)
    height: 100vh

    background-color: var(--main-bg-color)
    color: var(--text-color)
    
    @if $debug == false
        min-height: 100vh

// html
//     overflow-y: scroll
//     overflow-x: hidden

main
    padding: px-rem(20, m)
    min-height: calc(100vh - px-rem(100, m))

    @include at-least($mobile)
        padding: px-rem(40, d)
        min-height: calc(100vh - px-rem(68))

    @if ($debug == false)
        min-height: calc(100vh - px-rem(68))

body,
input,
textarea,
select,
button
    text-rendering: optimizeLegibility
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6
    color: var(--headings-color)
    font-family: var(--headings-font-family)
    font-weight: var(--headings-font-weight)
    line-height: var(--headings-line-height)
    padding: 0

    a
        color: inherit
        text-decoration: none


h1,
.h1
    font-size: px-rem(25, m)
    line-height: px-em(30, 25)
    margin: 0

    @include at-least($mobile)
        font-size: px-rem(50)
        line-height: px-em(50, 50)

h2,
.h2
    font-size: px-rem(20, m)
    line-height: px-em(22, 20)
    margin: 0

    @include at-least($mobile)
        font-size: px-rem(35)
        line-height: px-em(35, 35)

h3,
.h3
    font-size: px-rem(22)
    line-height: px-em(28, 22)
    margin: 0

    @include at-least($mobile)
        font-size: px-rem(20.8)
        line-height: px-em(22, 20.8)

h4,
.h4
    font-size: px-rem(18, m)
    line-height: px-em(24.5, 18)

    @include at-least($mobile)
        font-size: px-rem(16)
        line-height: px-em(18, 16)

h5,
.h5
    font-size: px-rem(14, m)
    line-height: px-em(23, 14)

    @include at-least($mobile)
        font-size: px-rem(12.8)
        line-height: px-em(14, 12.8)

h6,
.h6
    font-size: px-rem(12, m)
    line-height: px-em(17.5, 12)

    @include at-least($mobile)
        font-size: px-rem(11.2)
        line-height: px-em(12, 11.2)

.h7
    font-size: px-rem(10, m)
    line-height: px-em(14, 10)

    @include at-least($mobile)
        font-size: px-rem(10)
        line-height: px-em(11, 10)

a
    color: var(--link-color)
    transition: color 200ms ease
    text-decoration: none

    &:hover
        color: var(--brand-color)

p
    margin: 0 0 1em

p:empty,
p:last-child
    margin-bottom: 0

code
    background-color: var(--color-13)
    color: var(--color-11)
    padding: 0.05em
    border: 0.05em solid var(--color-14)
    border-radius: 0.15em
