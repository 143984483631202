@use '../abstracts' as *

.is-noscroll
    pointer-events: none
    height: 100%
    overflow: hidden

    .header,
    .main-nav,
    .top
        pointer-events: auto

.site-header
    z-index: 200
    position: sticky
    top: 0
    right: 0
    left: 0
    transition: all 0.3s ease-in-out
    background-color: var(--pref-color-1)

    &.hide-navbar
        transform: translateY(-100%)

    // START: Remove if not used
    .top-bar
        background-color: var(--brand-color)
        font-family:      var(--font-family-alt)
        color:            white

        .inner
            display: flex
            justify-content: space-between
            align-items: center

    // END: Remove if not used
    .header
        position: relative
        color: var(--brand-color-secondary)

        .header-inner
            display: flex
            align-items: center
            justify-content: space-between
            min-height: var(--header-height)

            .site-logo
                margin: 0
                max-width: px-rem(100, m)

                @include at-least($mobile)
                    flex: 1
                    max-width: px-rem(200, d)
    
                img
                    width: 100%
                    display: block

            .main-nav
                justify-content: flex-end
                
                @include at-least($mobile)
                    flex: 12

                .main
                    width: 100%