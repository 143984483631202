@use 'sass:math'
@use '../abstracts' as *

@mixin corner-ring($corner)
    max-width: px-rem(320, m)

    @include at-least($mobile)
        max-width: px-rem(1780)

    &:before
        @include bg-contain
        content: ""
        position: absolute
        width: px-rem(div(888.09, 4))
        height: px-rem(div(887.77, 4))
        background-image: url(../../../assets/images/logo-hoop.svg)
        filter: drop-shadow(px-rem(14) px-rem(14) px-rem(20) rgb(0,0,0, 40%))
        pointer-events: none
        
        @include at-least($mobile)
            width: px-rem(888.09)
            height: px-rem(887.77)

    @if $corner == 'top-left'
        margin-left: auto
        border-top-left-radius: px-rem(div(330, 4), m)

        @include at-least($mobile)
            border-top-left-radius: px-rem(330, d)

        &:before
            top: px-rem(div(-108.8, 4))
            right: unset
            bottom: unset
            left: px-rem(div(-111.4, 4))
            transform: scale(-1, -1)

            @include at-least($mobile)
                top: px-rem(-108.8)
                right: unset
                bottom: unset
                left: px-rem(-111.4)

    @else if $corner == 'top-right'
        border-top-right-radius: px-rem(div(330, 4), m)

        @include at-least($mobile)
            border-top-right-radius: px-rem(330, d)

        &:before
            left: px-rem(div(-111.4, 4))
            bottom: px-rem(div(-108.8, 4))
            bottom: unset
            left: unset
            transform: scale(1, -1)

            @include at-least($mobile)
                top: px-rem(-108.8)
                right: px-rem(-111.4)

    @else if $corner == 'bottom-left'
        margin-left: auto
        border-bottom-left-radius: px-rem(div(330, 4), m)

        @include at-least($mobile)
            border-bottom-left-radius: px-rem(330, d)

        &:before
            left: px-rem(div(-111.4, 4))
            bottom: px-rem(div(-108.8, 4))
            transform: scale(-1, 1)

            @include at-least($mobile)
                left: px-rem(-111.4)
                bottom: px-rem(-108.8)

    @else if $corner == 'bottom-right'
        border-bottom-right-radius: px-rem(div(330, 4), m)

        @include at-least($mobile)
            border-bottom-right-radius: px-rem(330, d)

        &:before
            right: px-rem(div(-111.4, 4))
            bottom: px-rem(div(-108.8, 4))

            @include at-least($mobile)
                right: px-rem(-111.4)
                bottom: px-rem(-108.8)

header,
aside,
section
    &.section:not(.no-bg) .section__outer
            background-image: url("../../../assets/images/bg.svg")

    .section
        &__outer
            @include bg-cover
            display: grid
            position: relative
            min-height: 78vh
            max-width: px-rem(320, m)
            width: 100%
            padding: px-rem(30, m) px-rem(20, m)
            border-radius: px-rem(20, m)
            background-color: white
            background-position: left
            box-shadow: px-rem(7) px-rem(7) px-rem(20) rgb(0,0,0, 75%)

            @include at-least($mobile)
                @include bg-cover
                max-width: px-rem(1780)
                padding: px-rem(50, d)
                border-radius: px-rem(40, d)
                min-height: px-rem(800)
                height: 82vh

            &.top-left
                @include corner-ring('top-left')

            &.top-right
                @include corner-ring('top-right')

            &.bottom-left
                @include corner-ring('bottom-left')

            &.bottom-right
                @include corner-ring('bottom-right')

            // .flex
            //     width: 100%
            //     place-self: center

        &__inner
            height: 100%
            display: grid

        &__content
            place-self: center
            text-align: center

        &__logo
            width: px-rem(200)
            margin-inline: auto
            margin-bottom: px-rem(30)

            @include at-least($mobile)
                width: px-rem(291.78)

            img
                width: 100%

        &__heading
            color: var(--brand-color)
            margin-bottom: px-em(5, 50)
            font-weight: 600

        &__subheading
            color: var(--brand-color-secondary)
            margin-bottom: px-em(30, 20)

            @include at-least($mobile)
                margin-bottom: px-em(50, 35)

        &__copy
            font-size: px-rem(16, m)
            line-height: px-em(60, 40)
            max-width: 100%
            margin-inline: auto
            margin-bottom: px-em(30, 16)

            @include at-least($mobile)
                font-size: px-rem(18)
                max-width: px-em(450, 18)
                margin-bottom: px-em(50, 18)

            a
                font-weight: 600
                position: relative
                color: var(--brand-color-secondary)
                
            p
                margin-bottom: px-em(5, 14)

                @include at-least($mobile)
                    margin-bottom: px-em(5, 20)

                &:hover
                    &:before
                        right: 0

                &:before
                    content: ""
                    position: absolute
                    top: 105%
                    right: 100%
                    left: 0
                    height: px-em(6, 40)
                    background-color: var(--brand-color)
                    transition: all 0.2s ease-in-out
                    border-radius: px-rem(5)

        &__accreditation
            font-size: px-rem(14, m)
            line-height: px-em(60, 40)
            font-weight: 600

            @include at-least($mobile)
                font-size: px-rem(16)

            .ionos-partner-logo
                display: block

                img
                    margin-inline: auto
                    margin-top: px-rem(20)
                    width: px-rem(200)

header,
section
    &.article-header
        .section-outer
            .section-inner
                .site-logo
                    width: 100%
                    display: flex
                    justify-content: center
                    align-items: center
                    font-weight: 700

                    &.m-bottom
                        margin-bottom: px-rem(50)

                    .plus
                        color: var(--brand-color)
                        margin-inline: px-rem(20)

                        @include at-least($mobile)
                            font-size: px-rem(60)

                    .logo-wrap
                        display: flex

                        img
                            width: auto
                            height: 100%
                            max-height: px-rem(50)

                            &.crater
                                max-height: px-rem(70)
                                transform: translateY(px-rem(-10))

                .section-copy.large
                    @include at-least($mobile)
                        max-width: clamp(62ch, 100%, 85ch)

                .hosting-locations
                    display: flex
                    justify-content: center
                    padding-bottom: px-rem(40)

                    .flag-wrap
                        display: flex
                        padding-inline: px-rem(10)

                        .flag
                            display: block
                            width: auto
                            height: 100%
                            max-height: px-rem(40)

section
    margin-block: px-rem(300)

    &:last-child
        margin-bottom: px-rem(50)

    // &.article-header